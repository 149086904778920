import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import ProductionsFilter from "../components/components/productions/ProductionsFilter"
import Production from "../components/components/productions/Production"
import { productionsPageHolder } from "./productions.module.scss"
import Seo from "../components/components/Seo"

// markup
const ProductionsPage = () => {
  const [statuses, setStatuses] = useState([])
  const [productions, setProductions] = useState([])
  const [allProductions, setAllProductions] = useState([])
  const [selectedStatus, setSelectedStatus] = useState("")
  const data = useStaticQuery(graphql`
    query ProductionsQuery {
      allWpProduction(sort: { order: ASC, fields: menuOrder }) {
        nodes {
          coProductionUK {
            coProductionUk
          }
          credits_concise {
            credit1
            credit2
            credit3
            credit4
          }
          excerpt
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1800
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          featuredImageFocus {
            focusPoint
          }
          title
          slug
          release_info {
            releaseDate
            releaseDateText
          }
          menuOrder
          terms {
            nodes {
              taxonomyName
              name
              termTaxonomyId
              id
            }
          }
          status
        }
      }
    }
  `)

  useEffect(() => {
    setAllProductions(data.allWpProduction.nodes)
  }, [setAllProductions, data])

  useEffect(() => {
    const statuses = allProductions.reduce(
      (accumulatedStatuses, production) => {
        let thisStatuses = production.terms
          ? production.terms.nodes
              .filter(node => node.taxonomyName === "production_status")
              .map(node => node.name)
          : ["no status"]

        thisStatuses = thisStatuses.filter(
          status => !accumulatedStatuses.includes(status)
        )

        return [...accumulatedStatuses, ...thisStatuses]
      },
      []
    )

    setStatuses(statuses)
  }, [allProductions, setStatuses])

  useEffect(() => {
    let filteredProductions = []

    if (selectedStatus === "") {
      filteredProductions = allProductions
    } else {
      filteredProductions = allProductions.filter(production => {
        let contains = false
        production.terms.nodes.forEach(term => {
          if (term.name === selectedStatus) contains = true
        })
        return contains
      })
    }

    setProductions(filteredProductions)
  }, [allProductions, selectedStatus, setProductions])

  return (
    <article className={productionsPageHolder}>
      <Seo title="Productions" description="About Good Chaos productions" />

      <h1 className="gambarinoHeader">Productions</h1>

      <ul className="allProductionsHolder">
        {productions.map((production, index) => (
          <li key={production.slug} className="productionContainer">
            <Production production={production} index={index} />
          </li>
        ))}
      </ul>
    </article>
  )
}

export default ProductionsPage
