import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  productionHolder,
  homePageProductionHolder,
} from "./Productions.module.scss"
import { Link } from "gatsby"
import { motion } from "framer-motion"

export default function Production({ production, index }) {
  const [hover, setHover] = useState("notHover")
  const image = getImage(production.featuredImage?.node.localFile)

  const hoverVariants = {
    notHover: {
      scale: 1,
    },
    hover: {
      scale: 1.05,
    },
  }

  return (
    <div
      className={productionHolder}
      onMouseEnter={() => setHover("hover")}
      onMouseLeave={() => {
        setHover("notHover")
      }}
      role="listitem"
    >
      <Link to={`/productions/${production.slug}`} title={production.title}>
        <div className="index">{(index + 1).toString().padStart(2, "0")}.</div>
        <div className="productionInner">
          <div className="productionInfo">
            <div className="topRight">
              <h3>{production.title}</h3>
              {production.credits_concise.credit1 && (
                <ul className="credits">
                  {production.credits_concise.credit1 && (
                    <li>{production.credits_concise.credit1}</li>
                  )}
                  {production.credits_concise.credit2 && (
                    <li>{production.credits_concise.credit2}</li>
                  )}
                  {production.credits_concise.credit3 && (
                    <li>{production.credits_concise.credit3}</li>
                  )}
                  {production.credits_concise.credit4 && (
                    <li>{production.credits_concise.credit4}</li>
                  )}
                </ul>
              )}
            </div>

            <div className="bottomRight">
              {production.excerpt && (
                <div
                  className="excerpt"
                  dangerouslySetInnerHTML={{ __html: production.excerpt }}
                />
              )}
              <div className="readMore">Read more</div>
            </div>
            <div className="bottomLeft">
              {production.coProductionUK.coProductionUk && (
                <div className="status">Co Production UK</div>
              )}
              {production.release_info.releaseDateText && (
                <div className="status">
                  {production.release_info.releaseDateText}
                </div>
              )}
            </div>
          </div>
          {image && (
            <motion.div
              className="featuredImage"
              variants={hoverVariants}
              initial="notHover"
              animate={hover}
            >
              <GatsbyImage
                image={image}
                alt={production.title}
                objectPosition={production.featuredImageFocus.focusPoint}
              />
            </motion.div>
          )}
        </div>
      </Link>
    </div>
  )
}

export function HomePageProduction({ production }) {
  const image = getImage(production.featuredImage.node.localFile)

  return (
    <div className={homePageProductionHolder}>
      <div className="productionInner">
        <Link to={`/productions/${production.slug}`} title={production.title}>
          <div className="productionInfo">
            <header>
              <div className="index">
                {production.menuOrder?.toString().padStart(2, "0")}.
              </div>
              <h3>{production.title}</h3>
            </header>
            {production.credits_concise.credit1 && (
              <ul className="credits">
                {production.credits_concise.credit1 && (
                  <li>{production.credits_concise.credit1}</li>
                )}
                {production.credits_concise.credit2 && (
                  <li>{production.credits_concise.credit2}</li>
                )}
                {production.credits_concise.credit3 && (
                  <li>{production.credits_concise.credit3}</li>
                )}
                {production.credits_concise.credit4 && (
                  <li>{production.credits_concise.credit4}</li>
                )}
              </ul>
            )}

            {production.excerpt && (
              <div
                className="excerpt"
                dangerouslySetInnerHTML={{ __html: production.excerpt }}
              />
            )}

            <footer>
              <div className="readMore">Read more</div>
              {production.release_info.releaseDateText && (
                <div className="status">
                  {production.release_info.releaseDateText}
                </div>
              )}
            </footer>
          </div>
          <div className="featuredImage">
            <GatsbyImage
              image={image}
              alt={production.title}
              objectPosition={production.featuredImageFocus.focusPoint}
            />
          </div>
        </Link>
      </div>
    </div>
  )
}
